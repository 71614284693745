<div *ngIf="!isHidden" id="streamlinea" class="m-content h-100 w-100" style="padding: 0px 0px !important; overflow-y: scroll;">
    <div class="m-portlet m-portlet--mobile">

        <div *ngIf="showGuide" id="dynamic-form-guide">
            <span class="debugger-style">{{l("FieldLabelWidth")}}</span> <kendo-numerictextbox class="style-debugger" [spinners]="true" [step]="1" [min]="125" [value]="fieldLabelWidth" format="n" (valueChange)="onFieldLabelWidthValueChange($event)"></kendo-numerictextbox>
            <span class="debugger-style">{{l("FieldControlWidth")}}</span> <kendo-numerictextbox class="style-debugger" [spinners]="true" [step]="1" [min]="6" [value]="fieldControlWidth" format="n2" (valueChange)="onFieldControlWidthValueChange($event)"></kendo-numerictextbox>
            <span class="debugger-style">{{l("GroupFieldSetWidth")}}</span> <kendo-numerictextbox class="style-debugger" [spinners]="true" [step]="1" [min]="100" [value]="groupFieldSetWidth" format="n" (valueChange)="onGroupFieldSetWidthValueChange($event)"></kendo-numerictextbox>
            <span class="debugger-style">{{l("GroupFieldSetGap")}}</span> <kendo-numerictextbox class="style-debugger" [spinners]="true" [step]="1" [min]="0" [value]="groupFieldSetGap" format="n" (valueChange)="onGridGapValueChange($event)"></kendo-numerictextbox>
            <span class="debugger-style"><label for="show-border-checkbox">{{l("ShowBorder")}}</label></span> <input id="show-border-checkbox" class="k-checkbox" type="checkbox" kendoCheckBox [checked]="showBorder" (click)="onShowBorderValueChange($event)" />
        </div>

        <div *ngIf="!formStructure?.componentSettings?.hideLabel" class="dynamic-form-label">{{l(formStructure?.componentSettings?.formLabelKey)}}</div>
        <div *ngIf="fieldSets.length !== 0" class="m-portlet__body">

            <form [formGroup]="dynamicForm" [style.gridGap]="gridGap" [style.grid-template-columns]="gridTemplateColumns" class="dynamic-form-container">

                <div *ngIf="useDefaultActionButton" class="k-form-buttons">
                    <button class="k-button delete-button" (click)="deleteForm()">{{l("Delete")}}</button>
                </div>

                <fieldset [style.border]="border" *ngFor="let fieldSet of fieldSets; let i=index" class="k-form-fieldset">
                    <legend [style.border]="border" *ngIf="fieldSet.fieldGroupIsLabelVisible" class="k-form-legend dynamic-form-fieldset-legend"><pre style="font-size: 1.3rem;">{{l(fieldSet.fieldGroupLabelKey)}}</pre></legend>

                    <div *ngFor="let field of fieldSet.fields; let i=index">
                        <div *ngIf="field.isActive" [hidden]="!field.showField" class="form-group dynamic-form-cell" [class.display-field-beside]="field.displayNextFieldBelowOrBeside === false">

                            <kendo-formfield *ngIf="field.controlTypeId === controlTypeNames.TextBox || field.controlTypeId.controlTypeName === controlTypeNames.TextBox" class="dynamic-field">
                                <label [hidden]="labelDisplay(!field.showLabel)"
                                       [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                       [for]="textBox"
                                       [title]="l(field.fieldDescriptionHint)"
                                       class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                    {{l(field.fieldLabelKey)}}
                                </label>
                                <input container="app-root"
                                       placement="top"
                                       autocomplete="new-password"
                                       [tooltip]="inputToolTips[field.databaseTableColumnName]"
                                       [adaptivePosition]="false"
                                       [ngClass]="{ textBoxRequired: isRequired(field) }"
                                       [class]="field.databaseTableColumnName"
                                       [style.width]="((applyFieldControlWidth === '' || field.optionAndValidation.width !== '') ? 'field.optionAndValidation.width' : applyFieldControlWidthForTextBox)"
                                       [formControlName]="field.databaseTableColumnName"
                                       [style]="inputStyles[field.databaseTableColumnName]"
                                       [type]="field?.optionAndValidation?.isPassword ? 'password' : 'text'"
                                       (focus)="onFocusChange(field)"
                                       (blur)="onBlurChange(field)"
                                       (input)="onTextBoxValueChange($event, field)"
                                       kendoTextBox
                                       #textBox />
                            </kendo-formfield>

                            <kendo-formfield *ngIf="field.controlTypeId === controlTypeNames.TextArea || field.controlTypeId.controlTypeName === controlTypeNames.TextArea" class="dynamic-field text-area-control-type">
                                <div class="text-area-control-type-label">
                                    <label [hidden]="labelDisplay(!field.showLabel)"
                                           [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                           [for]="textBox"
                                           [title]="l(field.fieldDescriptionHint)"
                                           class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                        {{l(field.fieldLabelKey)}}
                                    </label>
                                </div>
                                <kendo-textarea container="app-root"
                                                placement="top"
                                                [tooltip]="inputToolTips[field.databaseTableColumnName]"
                                                [adaptivePosition]="false"
                                                [ngClass]="{ textAreaRequired: isRequired(field) }"
                                                [formControlName]="field.databaseTableColumnName"
                                                [rows]="field.optionAndValidation.rows"
                                                [cols]="field.optionAndValidation.cols"
                                                [style]="inputStyles[field.databaseTableColumnName]"
                                                resizable="applyResizableOption(field.optionAndValidation.resizeOption)"
                                                (valueChange)="onTextAreaValueChange(field)"
                                                #textBox
                                                class="text-area-control-type-control">
                                </kendo-textarea>
                            </kendo-formfield>

                            <kendo-formfield *ngIf="field.controlTypeId === controlTypeNames.Numeric || field.controlTypeId.controlTypeName === controlTypeNames.Numeric" class="dynamic-field">
                                <label [hidden]="labelDisplay(!field.showLabel)"
                                       [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                       [for]="numericTextBox"
                                       [title]="l(field.fieldDescriptionHint)"
                                       class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                    {{l(field.fieldLabelKey)}}
                                </label>
                                <kendo-numerictextbox container="app-root"
                                                      placement="top"
                                                      [tooltip]="inputToolTips[field.databaseTableColumnName]"
                                                      [adaptivePosition]="false"
                                                      [ngClass]="{ numericRequired: isRequired(field) }"
                                                      [style.width]="(applyFieldControlWidth == '' ? 'field.optionAndValidation.width' : applyFieldControlWidth)"
                                                      [style]="inputStyles[field.databaseTableColumnName]"
                                                      [class]="field.databaseTableColumnName"
                                                      [formControlName]="field.databaseTableColumnName"
                                                      [min]="field?.optionAndValidation?.minimumValue"
                                                      [max]="field?.optionAndValidation?.maximumValue"
                                                      #numericTextBox
                                                      [spinners]="false"
                                                      [step]="1"
                                                      [format]="'n0'"
                                                      [decimals]="0"
                                                      (valueChange)="onNumericTextBoxValueChange(field)"></kendo-numerictextbox>
                            </kendo-formfield>

                            <kendo-formfield *ngIf="field.controlTypeId === controlTypeNames.Date || field.controlTypeId.controlTypeName === controlTypeNames.Date" class="dynamic-field">
                                <label [hidden]="labelDisplay(!field.showLabel)"
                                       [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                       [for]="datePicker"
                                       [title]="l(field.fieldDescriptionHint)"
                                       class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                    {{l(field.fieldLabelKey)}}
                                </label>
                                <kendo-datepicker container="app-root"
                                                  placement="top"
                                                  [tooltip]="inputToolTips[field.databaseTableColumnName]"
                                                  [adaptivePosition]="false"
                                                  [ngClass]="{ datePickerRequired: isRequired(field) }"
                                                  [style.width]="(applyFieldControlWidth == '' ? 'field.optionAndValidation.width' : applyFieldControlWidth)"
                                                  [style]="inputStyles[field.databaseTableColumnName]"
                                                  [class]="field.databaseTableColumnName"
                                                  [formControlName]="field.databaseTableColumnName"
                                                  #datePicker
                                                  [format]="field.optionAndValidation?.mask"
                                                  (valueChange)="onDatePickerValueChange(field)"></kendo-datepicker>
                            </kendo-formfield>

                            <kendo-formfield *ngIf="field.controlTypeId === controlTypeNames.ComboBox || field.controlTypeId.controlTypeName === controlTypeNames.ComboBox" class="dynamic-field">
                                <label [hidden]="labelDisplay(!field.showLabel)"
                                       [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                       [title]="l(field.fieldDescriptionHint)"
                                       class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                    {{l(field.fieldLabelKey)}}
                                </label>
                                <kendo-multiselect *ngIf="field?.optionAndValidation?.isMultiSelect"
                                                   container="app-root"
                                                   placement="top"
                                                   [tooltip]="inputToolTips[field.databaseTableColumnName]"
                                                   [adaptivePosition]="false"
                                                   [ngClass]="{ comboBoxRequired: isRequired(field) }"
                                                   [style.width]="(applyFieldControlWidth == '' ? 'field.optionAndValidation.width' : applyFieldControlWidth)"
                                                   [style]="inputStyles[field.databaseTableColumnName]"
                                                   [class]="field.databaseTableColumnName"
                                                   [attr.disabled]="disableComboBox(field) ? true : null"
                                                   [title]="comboBoxTitles[field.databaseTableColumnName]"
                                                   [data]="comboBoxData[field.databaseTableColumnName]"
                                                   [formControlName]="field.databaseTableColumnName"
                                                   [filterable]="field.optionAndValidation.isAutoComplete"
                                                   #combobox
                                                   [textField]="field.textField"
                                                   [valueField]="field.valueField"
                                                   [valuePrimitive]="true"
                                                   [virtual]="virtual"
                                                   [placeholder]="l('SelectItem')"
                                                   [popupSettings]="{ width: 'auto' }"
                                                   (focus)="onFocusChange(field)"
                                                   (blur)="onBlurChange(field)"
                                                   (filterChange)="onFilterChange($event)"
                                                   (valueChange)="onMultiSelectValueChange($event, field)">
                                </kendo-multiselect>
                                <kendo-combobox *ngIf="!field?.optionAndValidation?.isMultiSelect"
                                                container="app-root"
                                                placement="top"
                                                [tooltip]="inputToolTips[field.databaseTableColumnName]"
                                                [adaptivePosition]="false"
                                                [ngClass]="{ comboBoxRequired: isRequired(field) }"
                                                [style.width]="(applyFieldControlWidth == '' ? 'field.optionAndValidation.width' : applyFieldControlWidth)"
                                                [style]="inputStyles[field.databaseTableColumnName]"
                                                [class]="field.databaseTableColumnName"
                                                [attr.disabled]="disableComboBox(field) ? true : null"
                                                [title]="comboBoxTitles[field.databaseTableColumnName]"
                                                [data]="comboBoxData[field.databaseTableColumnName]"
                                                [formControlName]="field.databaseTableColumnName"
                                                [filterable]="field.optionAndValidation.isAutoComplete"
                                                #combobox
                                                [textField]="field.textField"
                                                [valueField]="field.valueField"
                                                [valuePrimitive]="true"
                                                [virtual]="virtual"
                                                [placeholder]="l('SelectItem')"
                                                [popupSettings]="{ width: 500 }"
                                                (focus)="onFocusChange(field)"
                                                (blur)="onBlurChange(field)"
                                                (filterChange)="onFilterChange($event)"
                                                (valueChange)="onComboBoxValueChange($event, field)">
                                </kendo-combobox>
                            </kendo-formfield>

                            <kendo-formfield *ngIf="(field.controlTypeId === controlTypeNames.DropDown || field.controlTypeId.controlTypeName === controlTypeNames.DropDown) && !hasDropDownTree() && !hasTreeView()" class="dynamic-field">
                                <label [hidden]="labelDisplay(!field.showLabel)"
                                       [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                       [for]="field.databaseTableColumnName"
                                       [title]="l(field.fieldDescriptionHint)"
                                       class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                    {{l(field.fieldLabelKey)}}
                                </label>
                                <kendo-dropdownlist container="app-root"
                                                    placement="top"
                                                    [tooltip]="inputToolTips[field.databaseTableColumnName]"
                                                    [adaptivePosition]="false"
                                                    [ngClass]="{ dropdownListRequired: isRequired(field) }"
                                                    [style.width]="(applyFieldControlWidth == '' ? 'field.optionAndValidation.width' : applyFieldControlWidth)"
                                                    [style]="inputStyles[field.databaseTableColumnName]"
                                                    [class]="field.databaseTableColumnName"
                                                    [attr.disabled]="disableComboBox(field) ? true : null"
                                                    [title]="comboBoxTitles[field.databaseTableColumnName]"
                                                    [data]="comboBoxData[field.databaseTableColumnName]"
                                                    [formControlName]="field.databaseTableColumnName"
                                                    [filterable]="field.optionAndValidation.isAutoComplete"
                                                    [textField]="field.textField"
                                                    [valueField]="field.valueField"
                                                    [valuePrimitive]="true"
                                                    [defaultItem]="defaultItem"
                                                    [virtual]="virtual"
                                                    [popupSettings]="{ width: 'auto' }"
                                                    (focus)="onFocusChange(field)"
                                                    (blur)="onBlurChange(field)"
                                                    (filterChange)="onFilterChange($event)"
                                                    (valueChange)="onComboBoxValueChange($event, field)">
                                </kendo-dropdownlist>
                            </kendo-formfield>

                            <kendo-formfield *ngIf="(field.controlTypeId === controlTypeNames.DropDown || field.controlTypeId.controlTypeName === controlTypeNames.DropDown) && hasDropDownTree()" class="dynamic-field">
                                <label [hidden]="labelDisplay(!field.showLabel)"
                                       [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                       [for]="field.databaseTableColumnName"
                                       [title]="l(field.fieldDescriptionHint)"
                                       class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                    {{l(field.fieldLabelKey)}}
                                </label>

                                <div *ngIf="selectedFirstLevelNode && selectedSecondLevelNode">
                                    {{ selectedFirstLevelNode }} -> {{ selectedSecondLevelNode }}
                                    <span *ngIf="selectedThirdLevelNode">-> {{ selectedThirdLevelNode }}</span>
                                </div>

                                <kendo-dropdowntree #dropDownTree
                                                    kendoDropDownTreeExpandable
                                                    [kendoDropDownTreeHierarchyBinding]="areaData"
                                                    textField="text"
                                                    valueField="id"
                                                    childrenField="areas"
                                                    [style.width]="applyFieldControlWidth === '' ? 'field.optionAndValidation.width' : applyFieldControlWidth"
                                                    [style]="inputStyles[field.databaseTableColumnName]"
                                                    [class]="field.databaseTableColumnName"
                                                    [formControlName]="field.databaseTableColumnName"
                                                    [popupSettings]="{ width: 'auto' }"
                                                    [filterable]="false"
                                                    (valueChange)="onTreeValueChange($event, field)">
                                    <ng-template kendoDropDownTreeNodeTemplate let-dataItem>
                                        <span [ngClass]="{'disabled-node': isFirstLevelNode(dataItem)}"
                                              [title]="isFirstLevelNode(dataItem) ? 'Expand to select sub-items' : ''">
                                            {{ dataItem.text }}
                                        </span>
                                    </ng-template>
                                </kendo-dropdowntree>
                            </kendo-formfield>

                            <kendo-formfield *ngIf="(field.controlTypeId === controlTypeNames.DropDown || field.controlTypeId.controlTypeName === controlTypeNames.DropDown) && hasTreeView()" class="dynamic-field">
                                <ng-template #editor>
                                    <div *ngIf="editTree" #editorContainer class="node-edit" (focusout)="handleEditorFocusOut($event, editorContainer)">
                                        <kendo-textbox #textBox
                                                       name="text"
                                                       [formControl]="textFormControl"
                                                       (keydown)="handleEditorEnter($event, field.databaseTableColumnName)"
                                                       (keydown.esc)="cancel()"></kendo-textbox>

                                        <button kendoButton (click)="save(field.databaseTableColumnName)">{{ l("Save") }}</button>
                                        <button kendoButton (click)="cancel()">{{ l("Cancel") }}</button>
                                    </div>
                                </ng-template>

                                <kendo-textbox #textbox required [style.width.px]="150" [style.margin-right.px]="5" [placeholder]="l('AddItemName')">
                                </kendo-textbox>

                                <button kendoButton [disabled]="checkName(textbox)" (click)="addNode(textbox, treeview, field.databaseTableColumnName)">{{ l("AddNewItem") }}</button>

                                <kendo-treeview #treeview
                                                [style.border]="'1px solid black'"
                                                [style.height.vh]="35"
                                                [style.margin-top.px]="5"
                                                [nodes]="treeViewData.transactionTypes"
                                                textField="typeName"
                                                childrenField="children"
                                                kendoTreeViewDragAndDrop
                                                kendoTreeViewDragAndDropEditing
                                                kendoTreeViewExpandable
                                                kendoTreeViewHierarchyBinding
                                                [loadOnDemand]="false"
                                                [children]="fetchChildren"
                                                [hasChildren]="hasChildren"
                                                (nodeDrop)="handleDrop($event, field.databaseTableColumnName)"
                                                (nodeClick)="handleNodeClick($event, treeMenu)">
                                    <ng-template kendoTreeViewNodeTemplate let-dataItem>
                                        <div *ngIf="activeItem?.dataItem?.id !== dataItem?.id; else editor">
                                            {{ dataItem.typeName }}
                                        </div>
                                    </ng-template>
                                    <ng-template kendoTreeViewDragClueTemplate let-action="action" let-sourceItem="sourceItem" let-destinationItem="destinationItem" let-text="text">
                                        <span>{{ getDragStatus(action, sourceItem, destinationItem) + " " + text }}</span>
                                    </ng-template>
                                </kendo-treeview>
                                <kendo-contextmenu #treeMenu [items]="treeViewContextMenuItems" (select)="onSelect($event, field.databaseTableColumnName)">
                                </kendo-contextmenu>
                            </kendo-formfield>

                            <kendo-formfield *ngIf="(field.controlTypeId === controlTypeNames.CheckBox || field.controlTypeId.controlTypeName === controlTypeNames.CheckBox) && !field.rightSidedLabel" class="dynamic-field">
                                <label [hidden]="labelDisplay(!field.showLabel)"
                                       [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                       [for]="field.databaseTableColumnName"
                                       [title]="l(field.fieldDescriptionHint)"
                                       class="dynamic-form-kendo-formfield-label k-checkbox-label col-sm-2">
                                    {{l(field.fieldLabelKey)}}
                                </label>

                                <input container="app-root"
                                       placement="top"
                                       [tooltip]="inputToolTips[field.databaseTableColumnName]"
                                       [adaptivePosition]="false"
                                       [id]="field.databaseTableColumnName"
                                       [style]="inputStyles[field.databaseTableColumnName]"
                                       class="k-checkbox"
                                       type="checkbox"
                                       [indeterminate]="field?.optionAndValidation?.indeterminateOption"
                                       [formControlName]="field.databaseTableColumnName"
                                       (click)="onCheckBoxValueChange(field)"
                                       #checkBox
                                       kendoCheckBox />
                            </kendo-formfield>

                            <div *ngIf="(field.controlTypeId === controlTypeNames.CheckBox || field.controlTypeId.controlTypeName === controlTypeNames.CheckBox) && field.rightSidedLabel" class="dynamic-field">
                                <input container="app-root"
                                       placement="top"
                                       [tooltip]="inputToolTips[field.databaseTableColumnName]"
                                       [adaptivePosition]="false"
                                       [id]="field.databaseTableColumnName"
                                       [style]="inputStyles[field.databaseTableColumnName]"
                                       class="k-checkbox"
                                       type="checkbox"
                                       [indeterminate]="field?.optionAndValidation?.indeterminateOption"
                                       [formControlName]="field.databaseTableColumnName"
                                       (click)="onCheckBoxValueChange(field)"
                                       #checkBox
                                       kendoCheckBox />

                                <label [hidden]="labelDisplay(!field.showLabel)"
                                       [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                       [for]="field.databaseTableColumnName"
                                       [title]="l(field.fieldDescriptionHint)"
                                       class="dynamic-form-kendo-formfield-label k-checkbox-label col-sm-2">
                                    {{l(field.fieldLabelKey)}}
                                </label>
                            </div>

                            <kendo-formfield *ngIf="field.controlTypeId === controlTypeNames.RadioButton || field.controlTypeId.controlTypeName === controlTypeNames.RadioButton"
                                             [ngClass]="{ radioButtonRequired: isRequired(field) }"
                                             class="radio-button-control-type">
                                <label [hidden]="labelDisplay(!field.showLabel)"
                                       [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                       [for]="field.databaseTableColumnName"
                                       [title]="l(field.fieldDescriptionHint)"
                                       class="dynamic-form-kendo-formfield-label k-checkbox-label col-sm-2 radio-button-control-type-label">
                                    {{l(field.fieldLabelKey)}}
                                </label>

                                <div *ngFor="let option of field.optionAndValidation.options; let i=index" class="radio-button-option">
                                    <label [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                           [for]="option.id + field.databaseTableColumnName"
                                           class="dynamic-form-kendo-formfield-label k-radio-label radio-button-control-type-option-label">{{l(option.labelKey)}}</label>
                                    <input container="app-root"
                                           placement="top"
                                           [tooltip]="inputToolTips[field.databaseTableColumnName]"
                                           [adaptivePosition]="false"
                                           [id]="option.id + field.databaseTableColumnName"
                                           type="radio"
                                           [name]="field.databaseTableColumnName"
                                           [value]="option.labelKey"
                                           [formControlName]="field.databaseTableColumnName"
                                           (click)="onRadioButtonValueChange(field)"
                                           kendoRadioButton />
                                </div>
                            </kendo-formfield>

                            <kendo-formfield *ngIf="field.controlTypeId === controlTypeNames.FileUpload || field.controlTypeId.controlTypeName === controlTypeNames.FileUpload" class="dynamic-field file-upload-control-type">
                                <label [hidden]="labelDisplay(!field.showLabel)"
                                       [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                       [for]="fileUpload"
                                       [title]="l(field.fieldDescriptionHint)"
                                       class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                    {{l(field.fieldLabelKey)}}
                                </label>
                                <kendo-upload container="app-root"
                                              placement="top"
                                              [tooltip]="inputToolTips[field.databaseTableColumnName]"
                                              [adaptivePosition]="false"
                                              [ngClass]="{ uploadRequired: isRequired(field) }"
                                              [style.width]="(applyFieldControlWidth == '' ? 'field.optionAndValidation.width' : applyFieldControlWidth)"
                                              [formControlName]="field.databaseTableColumnName"
                                              [style]="inputStyles[field.databaseTableColumnName]"
                                              [class]="field.databaseTableColumnName"
                                              [saveUrl]="uploadSaveUrl"
                                              [removeUrl]="uploadRemoveUrl"
                                              [restrictions]="retrieveFileRestrictions(field.optionAndValidation)"
                                              #fileUpload>
                                </kendo-upload>
                            </kendo-formfield>

                            <kendo-formfield *ngIf="field.controlTypeId === controlTypeNames.DisplayImage || field.controlTypeId.controlTypeName === controlTypeNames.DisplayImage" class="dynamic-field document-control-type">
                                <div (click)="showHideAttachmentsGrid()" class="cursor-pointer" [title]="l('ShowHideGridAttachments')">
                                    <span id="attach-file" class="k-icon k-i-attachment-45 k-i-clip-45 k-icon-md">
                                    </span>
                                    <div class="number-of-attachments">{{numberOfAttachments}}</div>
                                    <label [hidden]="!field.showLabel"
                                           style="margin-left: 30px;"
                                           [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                           [for]="kendoUploadAttachments"
                                           [title]="l(field.fieldDescriptionHint)">

                                        {{l("Attachments")}}
                                    </label>
                                </div>

                                <div style="margin-bottom: 18px;">
                                    <kendo-upload #kendoUploadAttachments
                                                  [formControlName]="field.databaseTableColumnName"
                                                  container="app-root"
                                                  placement="top"
                                                  [tooltip]="inputToolTips[field.databaseTableColumnName]"
                                                  [adaptivePosition]="false"
                                                  [ngClass]="{ uploadRequired: isRequired(field) }"
                                                  [saveUrl]="uploadSaveUrl"
                                                  [removeUrl]="uploadRemoveUrl"
                                                  [restrictions]="retrieveFileRestrictions(field.optionAndValidation)"
                                                  [showFileList]="true"
                                                  [multiple]="true"
                                                  (success)="successEventHandler($event)">

                                        <ng-template kendoUploadFileTemplate let-files let-state="state">

                                            <div style="display: inline-table;">

                                                <div>
                                                    <label [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                                           [for]="attachmentsCaption"
                                                           class="dynamic-form-kendo-formfield-label form-check-label col-sm-3">
                                                        {{l("Caption")}}
                                                    </label>

                                                    <input [style.width]="(applyFieldControlWidth == '' ? 'field.optionAndValidation.width' : applyFieldControlWidthForTextBox)"
                                                           [style]="inputStyles[field.databaseTableColumnName]"
                                                           (input)="onCaptionValueChange($event)"
                                                           [id]="field.databaseTableColumnName"
                                                           kendoTextBox
                                                           #attachmentsCaption />
                                                </div>

                                                <div>
                                                    <label [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                                           class="dynamic-form-kendo-formfield-label form-check-label col-sm-3">
                                                        {{l("FileName")}}
                                                    </label>

                                                    <span>{{files[0].name}}</span>
                                                </div>

                                                <dynamic-pdf-and-image-viewer *ngIf="files[0]?.extension === '.pdf'"
                                                                              #slsPdfviewer
                                                                              [attachmentsIsPreview]="true"
                                                                              [width]="'100px'"
                                                                              [pdfSrc]="files[0].fileSource"
                                                                              [itemName]="pdfName"></dynamic-pdf-and-image-viewer>

                                                <img *ngIf="files[0]?.extension === '.jpg' || files[0]?.extension === '.png'" [style.width]="(applyFieldControlWidth == '' ? 'field.optionAndValidation.width' : applyFieldControlWidth)" style="display: block;" [src]="files[0].fileSource" />

                                                <button kendoButton
                                                        *ngIf="showButton(state)"
                                                        (click)="removeFile(kendoUploadAttachments, files[0]?.uid)"
                                                        style="margin-top: -35px; float: right;">
                                                    {{l("Remove")}}
                                                </button>

                                            </div>
                                        </ng-template>

                                    </kendo-upload>

                                    <div [hidden]="!showAttachmentsGrid">
                                        <dynamic-grid #dynamicGrid
                                                      [gridStructureDataSourceName]="'GridStructureFormAttachments'"
                                                      [gridDataDataSourceName]="'GridDataFormAttachments'"
                                                      [gridId]="'00000000-0000-0000-0000-000000001001'"
                                                      [gridName]="'FormAttachments'"
                                                      [pageName]="'FormAttachments'"
                                                      [items]="attachmentItems"
                                                      [innerGridHeight]="290"
                                                      (cellClickEmitter)="cellClick($event)"
                                                      (getDataEmitter)="getData($event)">
                                        </dynamic-grid>

                                        <div>
                                            <dynamic-pdf-and-image-viewer *ngIf="attachmentsSource" #slsPdfviewer [width]="'440px'" [attachmentsIsPdf]="attachmentsIsPdf" [pdfSrc]="attachmentsSource" [itemName]="attachmentsName" [itemHeader]="attachmentsHeader" (onClose)="attachmentsSource = ''"></dynamic-pdf-and-image-viewer>
                                        </div>
                                    </div>
                                </div>

                            </kendo-formfield>

                            <kendo-formfield *ngIf="field.controlTypeId === controlTypeNames.InformationOnly || field.controlTypeId.controlTypeName === controlTypeNames.InformationOnly" class="information-only-control-type display-option-continuously" [ngClass]="{'display-option-wrap': field.optionAndValidation.displayOption === 'wrap', 'display-option-continuously': field.optionAndValidation.displayOption === 'continuously', 'display-option-truncate': field.optionAndValidation.displayOption === 'truncate'}">
                                <div [innerHTML]="l(field.fieldLabelKey)"></div>
                                <input type="hidden" [formControlName]="field.databaseTableColumnName" />
                            </kendo-formfield>
                            <!-- [title]="l(field.fieldLabelKey)" -->

                            <kendo-formfield *ngIf="field.controlTypeId === controlTypeNames.StaticText || field.controlTypeId.controlTypeName === controlTypeNames.StaticText" class="static-text-control-type display-option-truncate" [ngClass]="{'display-option-wrap': field.optionAndValidation.displayOption === 'wrap', 'display-option-continuously': field.optionAndValidation.displayOption === 'continuously', 'display-option-truncate': field.optionAndValidation.displayOption === 'truncate'}">
                                <a *ngIf="field.optionAndValidation?.link !== ''" [href]="field.optionAndValidation?.link"><div [innerHTML]="l(field.fieldLabelKey)"></div></a>
                                <div *ngIf="field.optionAndValidation?.link === ''" [innerHTML]="l(field.fieldLabelKey)"></div>
                                <input type="hidden" [formControlName]="field.databaseTableColumnName" />
                            </kendo-formfield>
                            <!-- [title]="l(field.fieldLabelKey)" -->

                            <kendo-formfield *ngIf="field.controlTypeId === controlTypeNames.Spacer || field.controlTypeId.controlTypeName === controlTypeNames.Spacer" id="spacer-control-type">
                                <input type="hidden" [formControlName]="field.databaseTableColumnName" />
                            </kendo-formfield>

                            <div *ngIf="field.controlTypeId === controlTypeNames.GridArray || field.controlTypeId.controlTypeName === controlTypeNames.GridArray" id="array-data-control-type">
                                <label [hidden]="labelDisplay(!field.showLabel)"
                                       [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                       [title]="l(field.fieldDescriptionHint)"
                                       class="dynamic-form-kendo-formfield-label k-checkbox-label col-sm-2">
                                    {{l(field.fieldLabelKey)}}
                                </label>
                                <dynamic-grid #dynamicGrid
                                              [gridStructureDataSourceName]="'GridStructureArrayData'"
                                              [useItemsInputAsData]="true"
                                              [hideHeader]="true"
                                              [allowAdd]="!field.editable ? false : true"
                                              [allowEdits]="!field.editable ? false : true"
                                              [allowDelete]="!field.editable ? false : true"
                                              [gridId]="'00000000-0000-0000-0000-000000001002'"
                                              [gridName]="'ArrayData'"
                                              [items]="setArrayFieldDataItems(field)"
                                              (updateItemsEmitter)="setArrayFieldAsDirtyIfValueChanged(field, $event)">
                                </dynamic-grid>
                            </div>
                            <div *ngIf="field.controlTypeId === controlTypeNames.FormArray || field.controlTypeId.controlTypeName === controlTypeNames.FormArray">
                                <kendo-formfield *ngFor="let arrayField of field.controlTypeId.formArrayDataControlType.formArrayDataFields; let i=index" class="dynamic-field">
                                    <label [hidden]="labelDisplay(!field.showLabel)"
                                           [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                           [for]="textBox"
                                           [title]="l(field.fieldDescriptionHint)"
                                           class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                        {{l(arrayField.fieldLabelKey)}}
                                    </label>
                                    <input container="app-root"
                                           placement="top"
                                           [tooltip]="inputToolTips[arrayField.fieldLabelKey]"
                                           [adaptivePosition]="false"
                                           [ngClass]="{ textBoxRequired: arrayField.isRequired }"
                                           [class]="arrayField.fieldLabelKey"
                                           [style.width]="(applyFieldControlWidth == '' ? 'field.optionAndValidation.width' : applyFieldControlWidthForTextBox)"
                                           [(ngModel)]="arrayControlTypeData[arrayField.fieldLabelKey]"
                                           [ngModelOptions]="{standalone: true}"
                                           [style]="inputStyles[arrayField.fieldLabelKey]"
                                           [attr.disabled]="!field.editable ? true : null"
                                           (focus)="onFocusChange(arrayField)"
                                           (blur)="onBlurChange(arrayField)"
                                           (input)="onArrayTextBoxValueChange(field)"
                                           kendoTextBox
                                           #textBox />
                                </kendo-formfield>
                            </div>
                        </div>

                        <div *ngIf="field.isActive" [hidden]="!field.showField" class="form-group dynamic-form-cell" [class.display-field-beside]="field.displayNextFieldBelowOrBeside === false">
                            <kendo-formfield *ngIf="field.optionAndValidation?.matchPassword === true && (field.controlTypeId === controlTypeNames.TextBox || field.controlTypeId.controlTypeName === controlTypeNames.TextBox)" class="dynamic-field">
                                <label [hidden]="labelDisplay(!field.showLabel)"
                                       [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet.fields, i)"
                                       [for]="textBox"
                                       [title]="l('PasswordRepeat')"
                                       class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                    {{l("PasswordRepeat")}}
                                </label>
                                <input container="app-root"
                                       placement="top"
                                       autocomplete="new-password"
                                       [tooltip]="inputToolTips[field.databaseTableColumnName]"
                                       [adaptivePosition]="false"
                                       [ngClass]="{ textBoxRequired: isRequired(field) }"
                                       [class]="field.databaseTableColumnName"
                                       [style.width]="(applyFieldControlWidth == '' ? 'field.optionAndValidation.width' : applyFieldControlWidthForTextBox)"
                                       [formControlName]="'confirmPassword'"
                                       [style]="inputStyles[field.databaseTableColumnName]"
                                       [type]="field?.optionAndValidation?.isPassword ? 'password' : 'text'"
                                       (focus)="onFocusChange(field)"
                                       (blur)="onBlurChange(field)"
                                       (input)="onTextBoxValueChange($event)"
                                       kendoTextBox
                                       #textBox />
                            </kendo-formfield>
                        </div>
                    </div>

                        <fieldset [style.border]="border" *ngFor="let fieldSet2 of fieldSet.childFieldSets; let i=index" class="k-form-fieldset">
                            <legend [style.border]="border" *ngIf="fieldSet2.fieldGroupIsLabelVisible" class="k-form-legend dynamic-form-fieldset-legend"><pre style="font-size: 1.3rem;">{{l(fieldSet2.fieldGroupLabelKey)}}</pre></legend>

                            <div *ngFor="let field2 of fieldSet2.fields; let i=index">

                                <div *ngIf="field2.isActive" [hidden]="!field2.showField" class="form-group dynamic-form-cell" [class.display-field-beside]="field2.displayNextFieldBelowOrBeside === false">
                                    <kendo-formfield *ngIf="field2.controlTypeId === controlTypeNames.TextBox || field2.controlTypeId.controlTypeName === controlTypeNames.TextBox" class="dynamic-field">
                                        <label [hidden]="labelDisplay(!field2.showLabel)"
                                               [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                               [for]="textBox"
                                               [title]="l(field2.fieldDescriptionHint)"
                                               class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                            {{l(field2.fieldLabelKey)}}
                                        </label>
                                        <input container="app-root"
                                               placement="top"
                                               autocomplete="new-password"
                                               [tooltip]="inputToolTips[field2.databaseTableColumnName]"
                                               [adaptivePosition]="false"
                                               [ngClass]="{ textBoxRequired: isRequired(field2) }"
                                               [style.width]="(applyFieldControlWidth == '' ? 'field2.optionAndValidation.width' : applyFieldControlWidthForTextBox)"
                                               [style]="inputStyles[field2.databaseTableColumnName]"
                                               [formControlName]="field2.databaseTableColumnName"
                                               (input)="onTextBoxValueChange($event, field2)"
                                               kendoTextBox
                                               #textBox />
                                    </kendo-formfield>

                                    <kendo-formfield *ngIf="field2.controlTypeId === controlTypeNames.TextArea || field2.controlTypeId.controlTypeName === controlTypeNames.TextArea" class="dynamic-field text-area-control-type">
                                        <div class="text-area-control-type-label">
                                            <label [hidden]="labelDisplay(!field2.showLabel)"
                                                   [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                                   [for]="textBox"
                                                   [title]="l(field2.fieldDescriptionHint)"
                                                   class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                                {{l(field2.fieldLabelKey)}}
                                            </label>
                                        </div>
                                        <kendo-textarea container="app-root"
                                                        placement="top"
                                                        [tooltip]="inputToolTips[field2.databaseTableColumnName]"
                                                        [adaptivePosition]="false"
                                                        [ngClass]="{ textAreaRequired: isRequired(field2) }"
                                                        [formControlName]="field2.databaseTableColumnName"
                                                        [class]="field2.databaseTableColumnName"
                                                        [style]="inputStyles[field2.databaseTableColumnName]"
                                                        [rows]="field2.optionAndValidation.rows"
                                                        [cols]="field2.optionAndValidation.cols"
                                                        resizable="applyResizableOption(field.optionAndValidation.resizeOption)"
                                                        (valueChange)="onTextAreaValueChange(field2)"
                                                        #textBox
                                                        class="text-area-control-type-control">
                                        </kendo-textarea>
                                    </kendo-formfield>

                                    <kendo-formfield *ngIf="field2.controlTypeId === controlTypeNames.Numeric || field2.controlTypeId.controlTypeName === controlTypeNames.Numeric" class="dynamic-field">
                                        <label [hidden]="labelDisplay(!field2.showLabel)"
                                               [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                               [for]="numericTextBox"
                                               [title]="l(field2.fieldDescriptionHint)"
                                               class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                            {{l(field2.fieldLabelKey)}}
                                        </label>
                                        <kendo-numerictextbox container="app-root"
                                                              placement="top"
                                                              [tooltip]="inputToolTips[field2.databaseTableColumnName]"
                                                              [adaptivePosition]="false"
                                                              [ngClass]="{ numericRequired: isRequired(field2) }"
                                                              [style.width]="(applyFieldControlWidth == '' ? 'field2.optionAndValidation.width' : applyFieldControlWidth)"
                                                              [class]="field2.databaseTableColumnName"
                                                              [style]="inputStyles[field2.databaseTableColumnName]"
                                                              [formControlName]="field2.databaseTableColumnName"
                                                              [min]="field2?.optionAndValidation?.minimumValue"
                                                              [max]="field2?.optionAndValidation?.maximumValue"
                                                              #numericTextBox
                                                              [spinners]="true"
                                                              [step]="1"
                                                              [format]="'n0'"
                                                              [decimals]="0"
                                                              (valueChange)="onNumericTextBoxValueChange(field2)"></kendo-numerictextbox>
                                    </kendo-formfield>

                                    <kendo-formfield *ngIf="field2.controlTypeId === controlTypeNames.Date || field2.controlTypeId.controlTypeName === controlTypeNames.Date" class="dynamic-field">
                                        <label [hidden]="labelDisplay(!field2.showLabel)"
                                               [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                               [for]="datePicker"
                                               [title]="l(field2.fieldDescriptionHint)"
                                               class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                            {{l(field2.fieldLabelKey)}}
                                        </label>
                                        <kendo-datepicker container="app-root"
                                                          placement="top"
                                                          [tooltip]="inputToolTips[field2.databaseTableColumnName]"
                                                          [adaptivePosition]="false"
                                                          [ngClass]="{ datePickerRequired: isRequired(field2) }"
                                                          [style.width]="(applyFieldControlWidth == '' ? 'field2.optionAndValidation.width' : applyFieldControlWidth)"
                                                          [style]="inputStyles[field2.databaseTableColumnName]"
                                                          [class]="field2.databaseTableColumnName"
                                                          [formControlName]="field2.databaseTableColumnName"
                                                          #datePicker
                                                          [format]="field2.optionAndValidation?.mask"
                                                          (valueChange)="onDatePickerValueChange(field2)"></kendo-datepicker>
                                    </kendo-formfield>

                                    <kendo-formfield *ngIf="field2.controlTypeId === controlTypeNames.ComboBox || field2.controlTypeId.controlTypeName === controlTypeNames.ComboBox" class="dynamic-field">
                                        <label [hidden]="labelDisplay(!field2.showLabel)"
                                               [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                               [title]="l(field2.fieldDescriptionHint)"
                                               class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                            {{l(field2.fieldLabelKey)}}
                                        </label>
                                        <kendo-multiselect *ngIf="field2?.optionAndValidation?.isMultiSelect"
                                                           container="app-root"
                                                           placement="top"
                                                           [tooltip]="inputToolTips[field2.databaseTableColumnName]"
                                                           [adaptivePosition]="false"
                                                           [ngClass]="{ comboBoxRequired: isRequired(field2) }"
                                                           [style.width]="(applyFieldControlWidth == '' ? 'field2.optionAndValidation.width' : applyFieldControlWidth)"
                                                           [style]="inputStyles[field2.databaseTableColumnName]"
                                                           [class]="field2.databaseTableColumnName"
                                                           [attr.disabled]="disableComboBox(field2) ? true : null"
                                                           [title]="comboBoxTitles[field2.databaseTableColumnName]"
                                                           [data]="comboBoxData[field2.databaseTableColumnName]"
                                                           [formControlName]="field2.databaseTableColumnName"
                                                           [filterable]="field2.optionAndValidation.isAutoComplete"
                                                           #combobox
                                                           [textField]="field2.textField"
                                                           [valueField]="field2.valueField"
                                                           [valuePrimitive]="true"
                                                           [virtual]="virtual"
                                                           [placeholder]="l('SelectItem')"
                                                           [popupSettings]="{ width: 'auto' }"
                                                           (focus)="onFocusChange(field2)"
                                                           (blur)="onBlurChange(field2)"
                                                           (filterChange)="onFilterChange($event)"
                                                           (valueChange)="onMultiSelectValueChange($event, field2)">
                                        </kendo-multiselect>
                                        <kendo-combobox *ngIf="!field2.optionAndValidation.isMultiSelect"
                                                        container="app-root"
                                                        placement="top"
                                                        [tooltip]="inputToolTips[field2.databaseTableColumnName]"
                                                        [adaptivePosition]="false"
                                                        [ngClass]="{ comboBoxRequired: isRequired(field2) }"
                                                        [style.width]="(applyFieldControlWidth == '' ? 'field2.optionAndValidation.width' : applyFieldControlWidth)"
                                                        [style]="inputStyles[field2.databaseTableColumnName]"
                                                        [class]="field2.databaseTableColumnName"
                                                        [attr.disabled]="disableComboBox(field2) ? true : null"
                                                        [title]="comboBoxTitles[field2.databaseTableColumnName]"
                                                        [data]="comboBoxData[field2.databaseTableColumnName]"
                                                        [formControlName]="field2.databaseTableColumnName"
                                                        [filterable]="field2.optionAndValidation.isAutoComplete"
                                                        #combobox
                                                        [textField]="field2.textField"
                                                        [valueField]="field2.valueField"
                                                        [valuePrimitive]="true"
                                                        [virtual]="virtual"
                                                        [placeholder]="l('SelectItem')"
                                                        (filterChange)="onFilterChange($event)"
                                                        (valueChange)="onComboBoxValueChange($event, field2)">
                                        </kendo-combobox>
                                    </kendo-formfield>

                                    <kendo-formfield *ngIf="field2.controlTypeId === controlTypeNames.DropDown || field2.controlTypeId.controlTypeName === controlTypeNames.DropDown" class="dynamic-field">
                                        <label [hidden]="labelDisplay(!field2.showLabel)"
                                               [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                               [for]="field2.databaseTableColumnName"
                                               [title]="l(field2.fieldDescriptionHint)"
                                               class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                            {{l(field2.fieldLabelKey)}}
                                        </label>
                                        <kendo-dropdownlist container="app-root"
                                                            placement="top"
                                                            [tooltip]="inputToolTips[field2.databaseTableColumnName]"
                                                            [adaptivePosition]="false"
                                                            [ngClass]="{ dropdownListRequired: isRequired(field2) }"
                                                            [style.width]="(applyFieldControlWidth == '' ? 'field2.optionAndValidation.width' : applyFieldControlWidth)"
                                                            [style]="inputStyles[field2.databaseTableColumnName]"
                                                            [class]="field2.databaseTableColumnName"
                                                            [attr.disabled]="disableComboBox(field2) ? true : null"
                                                            [title]="comboBoxTitles[field2.databaseTableColumnName]"
                                                            [data]="comboBoxData[field2.databaseTableColumnName]"
                                                            [formControlName]="field2.databaseTableColumnName"
                                                            [filterable]="field2.optionAndValidation.isAutoComplete"
                                                            [textField]="field2.textField"
                                                            [valueField]="field2.valueField"
                                                            [valuePrimitive]="true"
                                                            [defaultItem]="defaultItem"
                                                            (filterChange)="onFilterChange($event)"
                                                            (valueChange)="onComboBoxValueChange($event, field2)">
                                        </kendo-dropdownlist>
                                    </kendo-formfield>

                                    <kendo-formfield *ngIf="(field2.controlTypeId === controlTypeNames.CheckBox || field2.controlTypeId.controlTypeName === controlTypeNames.CheckBox) && !field2.rightSidedLabel" class="dynamic-field">
                                        <label [hidden]="labelDisplay(!field2.showLabel)"
                                               [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                               [for]="field2.databaseTableColumnName"
                                               [title]="l(field2.fieldDescriptionHint)"
                                               class="left-side dynamic-form-kendo-formfield-label k-checkbox-label col-sm-2">
                                            {{l(field2.fieldLabelKey)}}
                                        </label>

                                        <input container="app-root"
                                               placement="top"
                                               [tooltip]="inputToolTips[field2.databaseTableColumnName]"
                                               [adaptivePosition]="false"
                                               [id]="field2.databaseTableColumnName"
                                               class="k-checkbox"
                                               type="checkbox"
                                               [indeterminate]="field2?.optionAndValidation?.indeterminateOption"
                                               [formControlName]="field2.databaseTableColumnName"
                                               (click)="onCheckBoxValueChange(field2)"
                                               #checkBox
                                               kendoCheckBox />
                                    </kendo-formfield>

                                    <div *ngIf="(field2.controlTypeId === controlTypeNames.CheckBox || field2.controlTypeId.controlTypeName === controlTypeNames.CheckBox) && field2.rightSidedLabel" class="dynamic-field">
                                        <input container="app-root"
                                               placement="top"
                                               [tooltip]="inputToolTips[field2.databaseTableColumnName]"
                                               [adaptivePosition]="false"
                                               [id]="field2.databaseTableColumnName"
                                               class="k-checkbox"
                                               type="checkbox"
                                               [indeterminate]="field2?.optionAndValidation?.indeterminateOption"
                                               [formControlName]="field2.databaseTableColumnName"
                                               (click)="onCheckBoxValueChange(field2)"
                                               #checkBox
                                               kendoCheckBox />

                                        <label [hidden]="labelDisplay(!field2.showLabel)"
                                               [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                               [for]="field2.databaseTableColumnName"
                                               [title]="l(field2.fieldDescriptionHint)"
                                               class="right-side dynamic-form-kendo-formfield-label k-checkbox-label col-sm-2">
                                            {{l(field2.fieldLabelKey)}}
                                        </label>
                                    </div>

                                    <kendo-formfield *ngIf="field2.controlTypeId === controlTypeNames.RadioButton || field2.controlTypeId.controlTypeName === controlTypeNames.RadioButton"
                                                     [ngClass]="{ radioButtonRequired: isRequired(field2) }"
                                                     class="radio-button-control-type">
                                        <label [hidden]="labelDisplay(!field2.showLabel)"
                                               [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                               [for]="field2.databaseTableColumnName"
                                               [title]="l(field2.fieldDescriptionHint)"
                                               class="dynamic-form-kendo-formfield-label k-checkbox-label col-sm-2 radio-button-control-type-label">
                                            {{l(field2.fieldLabelKey)}}
                                        </label>

                                        <div *ngFor="let option of field2.optionAndValidation.options; let i=index" class="radio-button-option">
                                            <label [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                                   [for]="option.id + field2.databaseTableColumnName"
                                                   class="dynamic-form-kendo-formfield-label k-radio-label radio-button-control-type-option-label">{{l(option.labelKey)}}</label>
                                            <input container="app-root"
                                                   placement="top"
                                                   [tooltip]="inputToolTips[field2.databaseTableColumnName]"
                                                   [adaptivePosition]="false"
                                                   [id]="option.id + field2.databaseTableColumnName"
                                                   type="radio"
                                                   [name]="field2.databaseTableColumnName"
                                                   [value]="option.labelKey"
                                                   [formControlName]="field2.databaseTableColumnName"
                                                   (click)="onRadioButtonValueChange(field2)"
                                                   kendoRadioButton />
                                        </div>
                                    </kendo-formfield>

                                    <kendo-formfield *ngIf="field2.controlTypeId === controlTypeNames.FileUpload || field2.controlTypeId.controlTypeName === controlTypeNames.FileUpload" id="file-upload-control-type" class="dynamic-field">
                                        <label [hidden]="labelDisplay(!field2.showLabel)"
                                               [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                               [for]="fileUpload"
                                               [title]="l(field2.fieldDescriptionHint)"
                                               class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                            {{l(field2.fieldLabelKey)}}
                                        </label>
                                        <kendo-upload container="app-root"
                                                      placement="top"
                                                      [tooltip]="inputToolTips[field2.databaseTableColumnName]"
                                                      [adaptivePosition]="false"
                                                      [ngClass]="{ uploadRequired: isRequired(field2) }"
                                                      [style.width]="(applyFieldControlWidth == '' ? 'field2.optionAndValidation.width' : applyFieldControlWidth)"
                                                      [formControlName]="field2.databaseTableColumnName"
                                                      [style]="inputStyles[field2.databaseTableColumnName]"
                                                      [class]="field2.databaseTableColumnName"
                                                      [saveUrl]="uploadSaveUrl"
                                                      [removeUrl]="uploadRemoveUrl"
                                                      [restrictions]="retrieveFileRestrictions(field2.optionAndValidation)"
                                                      #fileUpload>
                                        </kendo-upload>
                                    </kendo-formfield>

                                    <kendo-formfield *ngIf="field2.controlTypeId === controlTypeNames.DisplayImage || field2.controlTypeId.controlTypeName === controlTypeNames.DisplayImage" class="dynamic-field document-control-type">
                                        <div (click)="showHideAttachmentsGrid()" class="cursor-pointer" [title]="l('ShowHideGridAttachments')">
                                            <span id="attach-file" class="k-icon k-i-attachment-45 k-i-clip-45 k-icon-md">
                                            </span>
                                            <div class="number-of-attachments">{{numberOfAttachments}}</div>
                                            <label [hidden]="labelDisplay(!field2.showLabel)"
                                                   style="margin-left: 30px;"
                                                   [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                                   [for]="kendoUploadAttachments"
                                                   [title]="l(field2.fieldDescriptionHint)">

                                                {{l("Attachments")}}
                                            </label>
                                        </div>

                                        <div style="margin-bottom: 18px;">
                                            <kendo-upload #kendoUploadAttachments
                                                          [formControlName]="field2.databaseTableColumnName"
                                                          container="app-root"
                                                          placement="top"
                                                          [tooltip]="inputToolTips[field2.databaseTableColumnName]"
                                                          [adaptivePosition]="false"
                                                          [ngClass]="{ uploadRequired: isRequired(field2) }"
                                                          [saveUrl]="uploadSaveUrl"
                                                          [removeUrl]="uploadRemoveUrl"
                                                          [restrictions]="retrieveFileRestrictions(field2.optionAndValidation)"
                                                          [showFileList]="true"
                                                          [multiple]="true"
                                                          (success)="successEventHandler($event)">

                                                <ng-template kendoUploadFileTemplate let-files let-state="state">

                                                    <div style="display: inline-table;">

                                                        <div>
                                                            <label [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                                                   [for]="attachmentsCaption"
                                                                   class="dynamic-form-kendo-formfield-label form-check-label col-sm-3">
                                                                {{l("Caption")}}
                                                            </label>

                                                            <input [style.width]="(applyFieldControlWidth == '' ? 'field2.optionAndValidation.width' : applyFieldControlWidthForTextBox)"
                                                                   [formControlName]="field2.databaseTableColumnName"
                                                                   [style]="inputStyles[field2.databaseTableColumnName]"
                                                                   kendoTextBox
                                                                   #attachmentsCaption />
                                                        </div>

                                                        <div>
                                                            <label [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                                                   class="dynamic-form-kendo-formfield-label form-check-label col-sm-3">
                                                                {{l("FileName")}}
                                                            </label>

                                                            <span>{{files[0].name}}</span>
                                                        </div>

                                                        <dynamic-pdf-and-image-viewer *ngIf="files[0]?.extension === '.pdf'"
                                                                                      #slsPdfviewer
                                                                                      [attachmentsIsPreview]="true"
                                                                                      [width]="'100px'"
                                                                                      [pdfSrc]="files[0].fileSource"
                                                                                      [itemName]="pdfName"></dynamic-pdf-and-image-viewer>

                                                        <img *ngIf="files[0]?.extension === '.jpg' || files[0]?.extension === '.png'" [style.width]="(applyFieldControlWidth == '' ? 'field2.optionAndValidation.width' : applyFieldControlWidth)" style="display: block;" [src]="files[0].fileSource" />

                                                        <button kendoButton
                                                                *ngIf="showButton(state)"
                                                                (click)="removeFile(kendoUploadAttachments, files[0]?.uid)"
                                                                style="margin-top: -35px; float: right;">
                                                            {{l("Remove")}}
                                                        </button>

                                                    </div>
                                                </ng-template>

                                            </kendo-upload>

                                            <div [hidden]="!showAttachmentsGrid">
                                                <dynamic-grid #dynamicGrid
                                                              [gridStructureDataSourceName]="'GridStructureFormAttachments'"
                                                              [gridDataDataSourceName]="'GridDataFormAttachments'"
                                                              [gridId]="'00000000-0000-0000-0000-000000001001'"
                                                              [gridName]="'FormAttachments'"
                                                              [pageName]="'FormAttachments'"
                                                              [items]="attachmentItems"
                                                              [innerGridHeight]="290"
                                                              (cellClickEmitter)="cellClick($event)"
                                                              (getDataEmitter)="getData($event)">
                                                </dynamic-grid>

                                                <div>
                                                    <dynamic-pdf-and-image-viewer *ngIf="attachmentsSource" #slsPdfviewer [width]="'440px'" [attachmentsIsPdf]="attachmentsIsPdf" [pdfSrc]="attachmentsSource" [itemName]="attachmentsName" [itemHeader]="attachmentsHeader" (onClose)="attachmentsSource = ''"></dynamic-pdf-and-image-viewer>
                                                </div>
                                            </div>
                                        </div>

                                    </kendo-formfield>

                                    <kendo-formfield *ngIf="field2.controlTypeId === controlTypeNames.InformationOnly || field2.controlTypeId.controlTypeName === controlTypeNames.InformationOnly" [ngClass]="{'display-option-wrap': field2.optionAndValidation.displayOption === 'wrap', 'display-option-continuously': field2.optionAndValidation.displayOption === 'continuously', 'display-option-truncate': field2.optionAndValidation.displayOption === 'truncate'}">
                                        <div [title]="l(field2.fieldLabelKey)">{{l(field2.fieldLabelKey)}}</div>
                                        <input type="hidden" [formControlName]="field2.databaseTableColumnName" />
                                    </kendo-formfield>

                                    <kendo-formfield *ngIf="field2.controlTypeId === controlTypeNames.StaticText || field2.controlTypeId.controlTypeName === controlTypeNames.StaticText" [ngClass]="{'display-option-wrap': field2.optionAndValidation.displayOption === 'wrap', 'display-option-continuously': field2.optionAndValidation.displayOption === 'continuously', 'display-option-truncate': field2.optionAndValidation.displayOption === 'truncate'}">
                                        <a *ngIf="field2.optionAndValidation?.link !== ''" [href]="field2.optionAndValidation?.link"><div [innerHTML]="l(field2.fieldLabelKey)"></div></a>
                                        <div *ngIf="field2.optionAndValidation?.link === ''" [title]="l(field2.fieldLabelKey)">{{l(field2.fieldLabelKey)}}</div>
                                        <input type="hidden" [formControlName]="field2.databaseTableColumnName" />
                                    </kendo-formfield>

                                    <kendo-formfield *ngIf="field2.controlTypeId === controlTypeNames.Spacer || field2.controlTypeId.controlTypeName === controlTypeNames.Spacer" id="spacer-control-type">
                                        <input type="hidden" [formControlName]="field2.databaseTableColumnName" />
                                    </kendo-formfield>

                                    <div *ngIf="field2.controlTypeId === controlTypeNames.GridArray || field2.controlTypeId.controlTypeName === controlTypeNames.GridArray" id="array-data-control-type">
                                        <label [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                               [for]="field2.databaseTableColumnName"
                                               [title]="l(field2.fieldDescriptionHint)"
                                               class="dynamic-form-kendo-formfield-label k-checkbox-label col-sm-2">
                                            {{l(field2.fieldLabelKey)}}
                                        </label>
                                        <dynamic-grid #dynamicGrid
                                                      [gridStructureDataSourceName]="'GridStructureArrayData'"
                                                      [useItemsInputAsData]="true"
                                                      [hideHeader]="true"
                                                      [allowEdits]="true"
                                                      [allowDelete]="true"
                                                      [gridId]="'00000000-0000-0000-0000-000000001002'"
                                                      [gridName]="'ArrayData'"
                                                      [items]="setArrayFieldDataItems(field2)"
                                                      (updateItemsEmitter)="setArrayFieldAsDirtyIfValueChanged(field2, $event)">
                                        </dynamic-grid>
                                    </div>

                                    <div *ngIf="field2.controlTypeId === controlTypeNames.FormArray || field2.controlTypeId.controlTypeName === controlTypeNames.FormArray">
                                        <kendo-formfield *ngFor="let arrayField of field2.controlTypeId.formArrayDataControlType.formArrayDataFields; let i=index" class="dynamic-field">
                                            <label [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                                   [for]="textBox"
                                                   class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                                {{l(arrayField.fieldLabelKey)}}
                                            </label>
                                            <input container="app-root"
                                                   placement="top"
                                                   [tooltip]="inputToolTips[arrayField.fieldLabelKey]"
                                                   [adaptivePosition]="false"
                                                   [ngClass]="{ textBoxRequired: arrayField.isRequired }"
                                                   [class]="arrayField.fieldLabelKey"
                                                   [style.width]="(applyFieldControlWidth == '' ? 'field2.optionAndValidation.width' : applyFieldControlWidthForTextBox)"
                                                   [(ngModel)]="arrayControlTypeData[arrayField.fieldLabelKey]"
                                                   [ngModelOptions]="{standalone: true}"
                                                   [style]="inputStyles[arrayField.fieldLabelKey]"
                                                   [attr.disabled]="!field2.editable ? true : null"
                                                   (focus)="onFocusChange(arrayField)"
                                                   (blur)="onBlurChange(arrayField)"
                                                   (input)="onArrayTextBoxValueChange(field2)"
                                                   kendoTextBox
                                                   #textBox />
                                        </kendo-formfield>
                                    </div>
                                </div>

                                <div *ngIf="field2.isActive" [hidden]="!field2.showField" class="form-group dynamic-form-cell" [class.display-field-beside]="field2.displayNextFieldBelowOrBeside === false">
                                    <kendo-formfield *ngIf="field2.optionAndValidation?.matchPassword && (field2.controlTypeId === controlTypeNames.TextBox || field2.controlTypeId.controlTypeName === controlTypeNames.TextBox)" class="dynamic-field">
                                        <label [hidden]="labelDisplay(!field2.showLabel)"
                                               [style.width]="labelWidth(dynamicFormKendoFormFieldLabelWidth, fieldSet2.fields, i)"
                                               [for]="textBox"
                                               [title]="l('PasswordRepeat')"
                                               class="dynamic-form-kendo-formfield-label form-check-label col-sm-2">
                                            {{l("PasswordRepeat")}}
                                        </label>
                                        <input container="app-root"
                                               placement="top"
                                               autocomplete="new-password"
                                               [tooltip]="inputToolTips[field2.databaseTableColumnName]"
                                               [adaptivePosition]="false"
                                               [ngClass]="{ textBoxRequired: isRequired(field2) }"
                                               [class]="field2.databaseTableColumnName"
                                               [style.width]="(applyFieldControlWidth == '' ? 'field2.optionAndValidation.width' : applyFieldControlWidthForTextBox)"
                                               [formControlName]="'confirmPassword'"
                                               [style]="inputStyles[field2.databaseTableColumnName]"
                                               [type]="field2?.optionAndValidation?.isPassword ? 'password' : 'text'"
                                               (focus)="onFocusChange(field2)"
                                               (blur)="onBlurChange(field2)"
                                               (input)="onTextBoxValueChange($event)"
                                               kendoTextBox
                                               #textBox />
                                    </kendo-formfield>
                                </div>
                            </div>

                        </fieldset>

</fieldset>

                <div *ngIf="useDefaultActionButton" class="k-form-buttons">
                    <button class="k-button k-primary submit-button" (click)="saveForm()">{{l("Save")}}</button>
                    <button class="k-button" (click)="clearForm()">{{l("Clear")}}</button>
                </div>
            </form>
        </div>
    </div>
</div>